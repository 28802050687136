import React from "react";
import { useEffect } from "react";
const Admin = () => {
    useEffect(()=>{
        window.location.href = " http://dvyc5dj70y755.cloudfront.net/";
    },[]);
    return (
        <>
        </>
    )
}
export default Admin